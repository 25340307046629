import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './wishlist.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';

const NikkeGuidesWishlistPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page wishlist-nikke'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Wishlist</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_shops.png"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Wishlist</h1>
          <h2>A guide for the wishlist in NIKKE.</h2>
          <p>
            Last updated: <strong>25/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Wishlist setup" />
        <p>
          Below you can find our wishlist guide for Ordinary Recruit. Each
          manufacturer will feature two sections:
        </p>
        <ul>
          <li>
            <strong>Pull Priority</strong>, which details what units you should
            own and get at least one copy,
          </li>
          <li>
            <strong>Dupe Priority</strong>, which mentions which Nikkes of the
            respective manufacturer you should aim to get duplicates for,
            separated by game mode. The main game modes are:
          </li>
          <ul>
            <li>Raids (which revolves around survival & damage min-maxing),</li>
            <li>
              Story/Tower (which focuses on CP padding to lower CP deficit),
            </li>
            <li>
              PvP (which focuses on CP padding, survivability, as well as
              additional damage).
            </li>
          </ul>
        </ul>
        <SectionHeader title="Elysion" />
        <h5>Pull Priority</h5>
        <div className="wishlist-table">
          <div className="column">
            <h5 className="prio-1">Must Have</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="d-killer-wife"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="privaty-unkind-maid"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="marciana"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="helm"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-2">Ideally Have</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="miranda"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="guillotine"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="zwei"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="maiden"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="diesel"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="poli"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="mast"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-3">Consider Getting</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="vesti"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="emma"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <h5>Dupe Priority</h5>
        <div className="wishlist-table">
          <div className="column">
            <h5>Raids</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="privaty-unkind-maid"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="guillotine"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="d-killer-wife"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="mast"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="diesel"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5>Story / Tower</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="d-killer-wife"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="privaty"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="privaty-unkind-maid"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="guillotine"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="diesel"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="poli"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="mast"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5>PvP</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="maiden"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="privaty-unkind-maid"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="poli"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="diesel"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="emma"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <SectionHeader title="Missilis" />
        <h5>Pull Priority</h5>
        <div className="wishlist-table ">
          <div className="column">
            <h5 className="prio-1">Must Have</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="liter"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="naga"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="tia"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="maxwell"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="jackal"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="ein"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="centi"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="tove"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="laplace"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-2">Ideally Have</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="pepper"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="drake"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="elegg"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-3">Consider Getting</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="epinel"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="admi"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <h5>Dupe Priority</h5>
        <div className="wishlist-table">
          <div className="column">
            <h5>Raids</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="laplace"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="maxwell"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="ein"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="naga"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="tove"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="drake"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="elegg"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="tia"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5>Story / Tower</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="naga"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="liter"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="maxwell"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="laplace"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="ein"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="tia"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5>PvP</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="laplace"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="jackal"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="centi"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="pepper"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="drake"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="liter"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="tia"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <SectionHeader title="Tetra" />
        <h5>Pull Priority</h5>
        <div className="wishlist-table">
          <div className="column">
            <h5 className="prio-1">Must Have</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="alice"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="blanc"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noir"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="soda-twinkling-bunny"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="dolla"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="volume"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="biscuit"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noise"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-2">Ideally Have</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="leona"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="rosanna"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="moran"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="sugar"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="alice-wonderland-bunny"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="yulha"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="exia"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="viper"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="frima"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="bay"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="sakura"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-3">Consider Getting</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="novel"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="nero"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="cocoa"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="ludmilla"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="folkwang"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="aria"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <h5>Dupe Priority</h5>
        <div className="wishlist-table">
          <div className="column">
            <h5>Raids</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="alice"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="soda-twinkling-bunny"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noir"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="exia"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="biscuit"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="viper"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="leona"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="frima"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="blanc"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noise"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5>Story / Tower</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="alice"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="soda-twinkling-bunny"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noir"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="blanc"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5>PvP</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="rosanna"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="alice"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="soda-twinkling-bunny"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noise"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="moran"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="blanc"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="biscuit"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="bay"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="viper"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="nero"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <SectionHeader title="About Wishlist" />
        <StaticImage
          src="../../../images/nikke/generic/wishlist.png"
          alt="Wishlist"
        />
        <p>
          Wishlist is a feature in Goddess of Victory: NIKKE that unlocks after
          you do your first 40 pulls on the regular banner. After that, you are
          able to pick 5 characters from each of the 3 main factions (called
          Manufacturers).
        </p>
        <p>
          When you fully fill the wishlist,{' '}
          <strong>
            every character you will pull from now will either belong to one of
            the 15 characters
          </strong>{' '}
          OR to the Pilgrim faction (they can't be added to the wishlist as they
          are a bit rarer - think about them like Light/Dark faction from most
          idle games).
        </p>
        <p>
          <strong>Important!</strong> You have to fill all 15 slots or else the
          wishlist won't work. You can't abuse it by just selecting one
          character per faction.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/wishlist2.png"
          alt="Wishlist"
        />
        <p>
          To summarize, thanks to this feature, you are able to target the
          characters that you plan to use in the long term or build teams
          around. So it's very important to setup your Wishlist right when you
          unlock the feature - and this guide will help you do it!
        </p>
        <p>
          Considering that there exist a Tower mode where on top of a
          free-for-all tower, exist faction towers, where you can only use
          characters from that faction, we made sure to fill the list with at
          least one character from each Burst time, so you can build a full
          team.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesWishlistPage;

export const Head: React.FC = () => (
  <Seo
    title="Wishlist | NIKKE | Prydwen Institute"
    description="A guide for the wishlist in NIKKE."
    game="nikke"
  />
);
